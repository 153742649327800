/* <Switch>
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/signin" component={Signin} />
          </Switch>*/

body {
  width: 100%;
  overflow-x: hidden;
}

.profile {
  padding: 5rem 1rem;
  overflow: hidden;
}
.gradient-custom {
  /* fallback for old browsers */
  background: #22c55e;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right bottom, #22c55e, #166534);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right bottom, #22c55e, #166534);

  border-radius: 0px;
}

/*header styling*/

.visibility:hover {
  cursor: pointer;
}

.header {
  background-color: white; /* Background color of the header */
  padding: 20px; /* Spacing inside the header */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #02871f; /* Text color */
  font-family: "Poppins", sans-serif;
  font-size: larger;
  font-weight: 400;
  padding-right: 20px;
  width: auto;
}

.lbut {
  background-color: #02871f;
  border-radius: 5px;
}

.logo img {
  width: 150px; /* Adjust the width of your logo image */
  height: 60px;
}

.logo imga {
  width: 200px; /* Adjust the width of your logo image */
  height: 60px;
  margin-left: 60px;
}

nav ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
  display: flex;
}

nav li {
  color: #02871f;
  margin-right: 30px;

  /* Spacing between navigation links */
}

nav a {
  text-decoration: none;
  margin: 0 -0.25rem;
  padding: 0 0.25rem;
  transition: color 0.6s ease-in-out, box-shadow 0.3s ease-in-out;
}

nav li:hover {
  text-decoration: none;
  transition: 0.8s linear;
  color: #000000;
  /* Change text color on hover (you can choose any color you like) */
}

nav a:active {
  text-decoration: none;
  transition: 0.8s linear;
  color: #000000;
  /* Change text color on hover (you can choose any color you like) */
}

.navlin {
  text-decoration: none;
}

nav a:active {
  background-color: #02871f;
  text-decoration: none;
  transition: 0.8s linear;
  color: #000000;
}

nav a::after {
  content: "";
  transition: 0.5s linear;
}

/*container1 styling*/

.conta {
  background-image: url("../src/images/imagess.jfif");
  background-size: cover;
  background-repeat: no-repeat;

  /*filter: brightness(35%)*/
}

.cont {
  justify-content: center;
  align-items: center;
}

.heig {
  height: 400px;
}
/*navbar styling*/
.ali {
  float: right;
}
.bgna {
  background-color: #02871f;
}

/*signin & signup btn styling*/
.sign1 {
  float: right;
  padding-top: 20px;
}

.signin {
  margin-left: 10px;
  margin-right: 55px;
  background-color: #02871f;
  font-size: 17px;
}

.signup {
  background-color: #02871f;
  font-size: 17px;
}
.hi {
  background-color: #02871f;
}

.signupin {
  background-color: #02871f;
  font-size: 17px;
  margin-top: 30px;
  margin-right: 50px;
  margin-left: 50px;
  margin: auto;
}
.register3 {
  margin-left: 500px;
  background-color: #02871f;
  margin-right: 20px;
}

.register4 {
  background-color: #02871f;
}

.register1 {
  margin-left: 500px;
  background-color: #02871f;
  margin-right: 20px;
}

.register2 {
  margin-right: 500px;
  background-color: #02871f;
}

.registerr {
  margin-left: 500px;
  background-color: #ffffff;
  margin-right: 20px;
  color: #000000;
}

.registerc {
  margin-right: 500px;
  background-color: #ffffff;
  color: #000000;
}

.regbuts {
  margin-top: 40px;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.three {
  display: flex;
  justify-content: left;
}

.coa {
  color: rgb(255, 255, 255);
  font-size: 50px;
  text-align: center;
  padding-top: 200px;
}
.co {
  color: rgb(255, 255, 255);
}

.searchbtn1 {
  margin-top: 40px;
  margin-left: 100px;
  padding-bottom: 150px;
}
.seac {
  background-color: #02871f;
  color: white;
  margin-top: 60px;
  padding-left: 15px;
  padding-right: 15px;
}
.pb {
  color: #ffffff;
  text-align: center;
}

.fdp {
  color: #ffffff;
  text-align: left;
  padding-left: 160px;
}

.seh {
  background-color: #02871f;
}

.searchb {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  width: 75%;
  border: solid #02871f;
  border-radius: 40px;
}

.searchin {
  padding-top: 20px;
  position: absolute;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #000000;
  width: 50%;
  border: solid #ffffff;
  border-radius: 40px;
}

.searchbut {
  padding-top: 12px;
  padding-bottom: 9px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffffff;
  color: #02871f;
}

.premium {
  background-color: #02871f;
  padding-top: 90px;
  padding-bottom: 90px;
}

.premium1 {
  background-color: #02871f;
  padding-top: 90px;
  padding-bottom: 90px;
}

.con {
  color: rgb(255, 255, 255);
  text-align: center;
}

.butshop {
  float: right;
  background-color: #ffffff;
  color: #02871f;
}

.but {
  background-color: white;
  color: black;
  border-radius: 25px;
  border-style: solid white;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 10px;
  padding-left: 10px;
}

.foo {
  padding-top: 70px;
  padding-bottom: 20px;
  background-color: #d9d9d9;
}

.fots {
  color: #02871f;
}

.fot {
  color: #02871f;
  padding-top: 25px;
  text-align: center;
  font-size: 17px;
}

.ith {
  padding-left: 20px;
  color: #02871f;
  font-size: 35px;
}

.ps {
  font-size: 17px;
  color: #000000;
}

.ithl {
  padding-left: 30px;
  padding-top: 10px;
}

.ithq {
  padding-left: 32px;
  color: #02871f;
}

.it {
  padding-left: 30px;
  padding-top: 10px;
}
.bottom {
  color: rgb(255, 255, 255);
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
}

.bgc {
  background-color: #02871f;
}

.imgg {
  padding-left: 40px;
  height: 25%;
  width: 25%;
}

.imgs {
  width: 100%;
}

@media only screen and (orientation: portrait) {
  /* styles for portrait orientation */
}

@media only screen and (orientation: landscape) {
  /* styles for landscape orientation */
}

.get {
  text-align: center;
  color: #02871f;
  padding-top: 60px;
}

.us {
  text-align: center;
  color: #ffffff;
  padding-top: 60px;
}

.planhead {
  text-align: center;
  color: #ffffff;
  padding-top: 60px;
  transform: scale(x);
  transition: 0.5s linear;
}

.btnget {
  width: 100%;
  margin-top: 20px;
}

.para {
  color: black;
  padding-top: 5px;
  text-align: center;
  font-size: 17px;
}

.para1 {
  color: #ffffff;
  padding-top: 5px;
  text-align: center;
  font-size: 17px;
}

.getps {
  padding-left: 20px;
  padding-right: 20px;
}

.rowstyle {
  padding-top: 40px;
}

.h3style {
  background-color: #02871f;
  color: white;
  text-align: center;
  font-size: 25px;
}

.ing {
  padding-top: 50px;
  padding-bottom: 50px;
}

.colstyle {
  padding-left: 30px;
}

.topp {
  margin-top: 0px;
  padding-top: 0%;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.zoom-out {
  animation: zoom-out 3s ease infinite;
}

@keyframes zoom-out {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.colstyle2 {
  margin: auto;
}

.rowst {
  background-color: #d9d9d9;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
}

.start {
  padding-top: 20px;
  padding-bottom: 90px;
  background-color: #d9d9d9;
}

.colr {
  background-color: #02871f;
}

.up {
  padding-top: 55px;
  padding-bottom: 90px;
  background-color: #02871f;
}

.mid {
  padding-top: 70px;
  padding-bottom: 90px;
  background-color: #ffffff;
  margin: auto;
}

.most1 {
  background-color: #ffffff;
  color: #02871f;
  color: #000;
  margin: auto;
}

.most2 {
  background-color: #02871f;
  color: #ffffff;
  margin: auto;
}

.carti {
  float: right;
}
.rig {
  text-align: right;
  color: #000000;
}

.lef {
  text-align: left;
  color: #000000;
}

.imgw {
  width: 100%;
  height: 120px;
}

.imgwg {
  width: 100%;
}

.imtr :hover {
  transition-timing-function: ease-in;
  transition: 0.6s;
  transform: translateY(130%);
}
/* about grabit styling in about page*/
.imgwa {
  width: 100%;
  height: 350px;
  padding-left: 60px;
}

.topm {
  padding-top: 90px;
  padding-bottom: 90px;
}

.padd {
  padding-left: 60px;
  padding-right: 40px;
}
.ground {
  background-color: #fdfdfd;
  color: #000000;
  margin-bottom: 15px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.formspace {
  padding-top: 30px;
}

.help {
  color: white;
  text-align: center;
  font-size: 30px;
}

.submit {
  background-color: #ffffff;
  color: black;
  border-radius: 15px;
  margin-left: 230px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 250px;
}

.line {
  width: 75px;
  height: 5px;
  background-color: #02871f;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.line2 {
  width: 75px;
  align-content: left;
  align-self: flex-start;
  height: 5px;
  background-color: #02871f;
  margin-top: 10px;
  margin-bottom: 8px;
  margin-left: 35px;
}

.line3 {
  width: 75px;
  align-content: left;
  align-self: flex-start;
  height: 5px;
  background-color: #fdfdfd;
  margin-top: 10px;
  margin-bottom: 8px;
  margin-left: 160px;
}

.line1 {
  width: 75px;
  height: 5px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.line4 {
  width: 75px;
  height: 5px;
  background-color: #ffffff;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 132px;
}

.App {
  display: flex;
  flex: 1;
  height: 145vh;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App1 {
  display: flex;
  flex: 1;
  height: 80vh;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App6 {
  display: flex;
  flex: 1;
  background-color: #ffffff;
  height: 70vh;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.App5 {
  display: flex;
  flex: 1;
  height: 110vh;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App2 {
  display: flex;
  flex: 1;
  height: 150vh;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.App4 {
  display: flex;
  flex: 1;
  height: 110vh;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px;
}
.margina {
  margin: auto;
}

.shopsf {
  background-color: #02871f;
}

fieldset {
  display: flex;
  width: 412px;
  padding: 24px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
  font-size: 17px;
  color: #000000;
}

sup {
  color: red;
  top: -2px;
  font-size: 95%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

input,
select {
  border: 1px solid;
  height: 32px;
  padding: 0 8px;
  background-color: #02871f;
  border-radius: 40px;
  color: #ffffff;
}

.labels {
  color: #ffffff;
}
.labelsc {
  color: #000000;
}
.inputrs {
  background-color: #ffffff;
  color: #02871f;
}

.contact {
  margin-top: 15px;
}
.Field {
  position: relative;
  padding-bottom: 18px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.FieldError {
  position: absolute;
  bottom: -12px;
  color: red;
  font-size: 12px;
}

.Row {
  display: flex;
  flex-direction: row;
}
.rowsecondary {
  padding-top: 60px;
  padding-bottom: 60px;
}

.colsecondary {
  margin: auto;
}
.formbuts {
  padding: 12px;
  width: 240px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #02871f;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 65px;
  margin-top: 15px;
}

.formbutss {
  padding: 12px;
  width: 240px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  color: #02871f;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin: 58px;
  margin-top: 15px;
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

/*background styling of the image containers*/
.sign {
  background-image: url("../src/images/imagess.png");
  background-blend-mode: darken;
  background-size: cover;
  background-repeat: no-repeat;
}

/*background styling of the image containers*/
.plan {
  background-image: url("../src/images/imagess.png");
  background-blend-mode: darken;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 50px;
}

.formb {
  background-color: #ffffff;
}

.formbs {
  background-color: #02871f;
}

.forma {
  text-decoration: solid;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #02871f;
  text-align: center; /*text align of form heading*/
}

.formas {
  text-decoration: solid;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #ffffff;
  text-align: center; /*text align of form heading*/
}

.inputc {
  color: #ffffff;
}

::placeholder {
  color: #ffffff;
  opacity: 0.7;
}

.loginalt {
  text-align: left;
  text-decoration: none;
  color: #02871f;
  padding-left: 30px;
}

.loginalti {
  text-align: center;
  text-decoration: none;

  margin-bottom: 20px;
  color: #02871f;
}

.loginat {
  text-align: right;
  text-decoration: none;
  color: #ff0202;
  float: right;
  padding-right: 25px;
}

.linksl {
  padding-top: 15px;
  padding-bottom: 40px; /*padding styling of create account/forgot password*/
}

.linksls {
  padding-top: 15px;
  text-align: center;
  padding-bottom: 40px; /*padding styling of create account/forgot password*/
}

.back {
  background-color: #ffffff;
  padding-bottom: 80px;
}

.back1 {
  background-color: #02871f;
  padding-bottom: 80px;
}

.back2 {
  background-color: #d9d9d9;
  padding-bottom: 80px;
}

.act {
  color: #ffffff;
  font-size: 17px;
}
.seco {
  color: #02871f;
  text-align: center;
}

.acta {
  color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 17px;
  text-align: right;
  padding-right: 45px;
  text-decoration: none;
}
.textar {
  background-color: #02871f;
}

.selecti {
  margin-top: 40px;
  margin-bottom: 40px; /*dropdown of payment select option*/
}

.table-container {
  margin: 0px 40px;
  overflow-x: auto;
}

.icon-hover:hover {
  border-color: #3b71ca !important;
  background-color: white !important;
  color: #3b71ca !important;
}

.icon-hover:hover i {
  color: #3b71ca !important;
}

.pur {
  color: #ffffff;
  text-align: center;
}

.fontsi {
  font-size: 15px;
}

.butspad {
  margin-top: 20px;
}
.promop {
  padding-top: 12px;
  padding-bottom: 12px;
}
.title {
  margin-bottom: 5vh;
}
.card {
  margin: auto;
  max-width: 950px;
  width: 90%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  border: transparent;
}
@media (max-width: 767px) {
  .card {
    margin: 3vh auto;
  }
  .profile {
    padding: 2rem 1rem;
  }
  .table-container {
    margin: 0px 10px;
  }
}
.cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}
@media (max-width: 767px) {
  .cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
  }
}
.summary {
  background-color: #ddd;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 4vh;
  color: rgb(65, 65, 65);
}
@media (max-width: 767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}
.summary .col-2 {
  padding: 0;
}
.summary .col-10 {
  padding: 0;
}
.row {
  /* box-sizing: border-box; */
}

.title b {
  font-size: 1.5rem;
}
.main {
  margin: 0;
  padding: 2vh 0;
  width: 100%;
}
.col-2,
.col {
  padding: 0 1vh;
}
.alinkc {
  padding: 0 1vh;
  text-decoration: none;
}

img {
  width: 3.5rem;
}

hr {
  margin-top: 1.25rem;
}
.fordelivery {
  padding: 2vh 0;
}
.selection {
  border: 1px solid rgb(255, 255, 255);
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: #02871f;
}
.delivery {
  border: 1px solid rgba(0, 0, 0, 0.137);
  padding: 1vh;
  margin-bottom: 4vh;
  outline: none;
  width: 100%;
  background-color: rgb(247, 247, 247);
}

.checki {
  background-color: #02871f;
  border-color: #ffffff;
  color: white;
  width: 100%;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}
.checki:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;

  transition: none;
}
.checki:hover {
  color: white;
}
.alinkc {
  color: #02871f;
  text-decoration: none;
  font-size: 17px;
}
.alinkc:hover {
  color: black;
  text-decoration: none;
}

.stylep {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2vh 0;
}
.styleI {
  padding-left: 0;
}

.cartco {
  padding-top: 90px;
  padding-bottom: 90px;
}

.zoom {
  transition: transform 0.2s; /* Animation */
}
.zoom:hover {
  transform: scale(
    0.9
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  width: 100%;
}

.heada {
  transform: translate(30px);
  transition-property: transform infinite;
  transition-duration: 5s;
  transition-timing-function: linear;
}

.amountplan {
  color: #02871f;
  text-align: center;
  padding-bottom: 15px;
  font-size: 50px;
}

.amount {
  color: #000000;
  text-align: center;
  padding-top: 15px;
  text-decoration: underline;
}

.prom {
  color: #02871f;
}

.testimonial {
  color: #ffffff;
  background-color: #02871f;
  text-align: center;
  margin: auto;
  margin-top: 60px;
  box-shadow: #02871f;
}
.top {
  padding-top: 50px;
}

.testimonial .pic {
  margin-top: 30px;
  margin-left: 20px;
  align-items: center;
  border: 5px solid #ffffff;
  display: inline-block;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonial .pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.testimonial .testimonial-content {
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 30px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.tesm {
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
}

.testimonial .testimonial-title small {
  color: #000000;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.icons {
  margin-left: 7px;
}

.container {
  width: 400px;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 5px 12px -2px rgba(0, 0, 0, 0.1),
    0 18px 36px -6px rgba(0, 0, 0, 0.1);
}

.container .title {
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.container form input {
  display: none;
}

.container form .category {
  margin-top: 10px;
  padding-top: 20px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
}

.category label {
  /* width: 100%; */
  /* height: 65px; */
  height: 145px;
  padding: 20px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  position: relative;
}

/* label:nth-child(2), label:nth-child(3){
  margin: 15px 0;
} */

#visa:checked ~ .category .visaMethod,
#mastercard:checked ~ .category .mastercardMethod,
#paypal:checked ~ .category .paypalMethod,
#AMEX:checked ~ .category .amexMethod {
  box-shadow: 0px 0px 0px 1px #6064b6;
}

#visa:checked ~ .category .visaMethod .check,
#mastercard:checked ~ .category .mastercardMethod .check,
#paypal:checked ~ .category .paypalMethod .check,
#AMEX:checked ~ .category .amexMethod .check {
  display: block;
}

label .imgName {
  display: flex;
  /* justify-content: space-between;
   */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.imgName span {
  /* margin-left: 20px; */
  font-family: Arial, Helvetica, sans-serif;

  position: absolute;
  top: 72%;
  transform: translateY(-72%);
}

.imgName .imgContainer {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 35%;
  transform: translateY(-35%);
}

img {
  width: 50px;
  height: auto;
}

.visa img {
  width: 80px;
  /* margin-left: 5px; */
}

.mastercard img {
  width: 65px;
}

.paypal img {
  width: 80px;
}

.AMEX img {
  width: 50px;
}

.check {
  display: none;
  position: absolute;
  top: -4px;
  right: -4px;
}

.check i {
  font-size: 18px;
}

.heroText {
  font-size: 2.3rem;
  font-weight: bold;
}

.hero {
  min-height: 30rem;
}

.slick-slide {
  padding: 10px;
}

/*small devices(landscape phones, 576px and up)*/
@media only screen and(min-width:576px) {
  h4 {
    font-size: 10px;
  }
}

/*medium devices(tablets, 768px and up)*/
@media only screen and (max-width: 767px) {
  .heroText {
    font-size: 1.5rem;
  }
  .hero {
    min-height: 20rem;
  }
}

/*large devices(desktop, 992px and up)*/
@media (min-width: 992px) {
}

/*extra large devices(landscape phones, 1200px and up)*/
@media (min-width: 1200px) {
}

/*set width to make card desk cards 100% width*/
@media (max-width: 992px) {
}
